<template>
  <div>
    <TAdvancedSearch ref="TAdvancedSearch">
      <template v-slot:advanced-search>
        <Advanced
          v-if="tableSubmit != null"
          :searchConfiguration.sync="searchConfiguration"
          :searchObj.sync="tableSubmit"
          :tableId.sync="tableData.tabId"
        ></Advanced>
      </template>
    </TAdvancedSearch>

    <KTPortlet>
      <template v-slot:body>
        <b-overlay :show="loading" class="head-loading">
          <div class="operate-box">
            <Export
              v-b-popover.hover.topleft=""
              :exportText="'Export Excel'"
              :exportUrl="exportUrl"
              :exportDisabled="exportDisabled"
              fileName="Purchase Summary Report"
            ></Export>
          </div>
          <CustomTable
            v-if="tableSubmit != null"
            :itemsFn="itemsFn"
            :tableData="tableData"
            :sortName="sort_name"
            :tableObj="tableSubmit"
            :tableId.sync="tableData.tabId"
            :options="options"
          ></CustomTable>
        </b-overlay>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import TAdvancedSearch from '@/components/TAdvancedSearch/Index.vue';
import KTPortlet from '@/views/content/Portlet';
import Advanced from '@/components/Search/Ordinary.vue';
import CustomTable from '@/components/Table/Index.vue';
import Export from '@/components/Export/Index.vue';
import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import apiBus from '@/common/apiBus/index';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'PurchaseSummary',
  components: { TAdvancedSearch, KTPortlet, Advanced, CustomTable, Export },
  data() {
    return {
      // 高级搜索配置
      loading: false,
      exportUrl: '',
      exportDisabled: false,
      exportErrorText: '',
      searchConfiguration: {
        brand: {
          type: 'input',
          text: 'Brand',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        brand_article_number: {
          type: 'input',
          text: 'Brand Article Number',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        order_date: {
          type: 'datepicker_from_to',
          md: '6',
          text: 'Order Date',
          limit: {
            end: 0
          },
          from: {
            placeholder: 'From'
          },
          to: {
            placeholder: 'To'
          }
        },
        sales_channel: {
          type: 'select',
          text: 'Sales Channel',
          // icon: 'bar-chart-fill',
          placeholder: this.$t('page.please_choose'),
          multiple: true,
          options: []
        },
        keyword: {
          type: 'input',
          text: 'Keyword',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        }
      },
      tableData: {
        fields: [
          {
            key: 'image',
            label: 'Article Image',
            variant: 'w-110'
          },
          {
            key: 'article_information',
            label: 'Article Information',
            variant: 'mx-300'
          },
          {
            key: 'qty',
            label: 'Target Quantity'
          },
          {
            key: 'not_purchased_qty',
            label: 'To be purchased'
          },
          {
            key: 'reserved_qty',
            label: 'Reserved'
          },
          {
            key: 'confirmed_qty',
            label: 'Confirmed'
          },
          {
            key: 'delivered_qty',
            label: 'Delivered'
          },
          {
            key: 'stored_qty',
            label: 'Stored'
          },
          {
            key: 'action',
            label: ''
          }
        ],
        tabId: 'purchase_summary',
        rowClass: '',
        class: 'new_table',
        items: [],
        status: null,
        totalItems: 9999999999999,
        pageOptions: [10, 30, 50, 100, 200]
      },
      // 搜索提交数据
      tableSubmit: null,
      initialTableSubmit: {
        brand: null,
        brand_article_number: null,
        order_date_from: null,
        order_date_to: null,
        sales_channel: null,
        keyword: null,
        limit: 100,
        page: 1
      },
      sort_name: '',

      // 表格数据
      save_data: [],
      options: {
        sales_channel: [],
        supplier_list: []
      }
    };
  },

  methods: {
    /**
     * param 将要转为URL参数字符串的对象
     * key URL参数字符串的前缀
     * encode true/false 是否进行URL编码,默认为true
     *
     * return URL参数字符串
     */
    urlEncode(param, key, encode) {
      if (param == null) return '';
      var paramStr = '';
      var t = typeof param;
      if (t == 'string' || t == 'number' || t == 'boolean') {
        if (key != 'limit' && key != 'page' && key != 'tab_type') {
          paramStr +=
            '&' +
            key +
            '=' +
            (encode == null || encode ? encodeURIComponent(param) : param);
        }
      } else {
        for (var i in param) {
          var k =
            key == null
              ? i
              : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
          paramStr += this.urlEncode(param[i], k, encode);
        }
      }
      return paramStr;
    },
    exportUrlFn(data) {
      console.log(data);
      this.exportUrl = `/rest/api/purchase/download-purchase-summary-list?${this.urlEncode(
        data
      )}`;
    },
    searchTableData(data) {
      var tableData = [];
      data.forEach(key => {
        let {
          id,
          image_url,
          brand,
          brand_article_number,
          product_name_en,
          product_name,
          color,
          color_name,
          size,
          qty,
          not_purchased_qty,
          reserved_qty,
          confirmed_qty,
          delivered_qty,
          stored_qty,
          product_id
        } = key;

        tableData.push({
          id,
          image: image_url,
          article_information: {
            brand: brand,
            brand_article_number: brand_article_number,
            product_name:
              product_name_en != '' && product_name_en != null
                ? product_name_en
                : product_name,
            color: color,
            color_name: color_name,
            size: size
            // pos_article_no: pos_article_no
          },
          qty: qty,
          not_purchased_qty: not_purchased_qty,
          reserved_qty: reserved_qty,
          confirmed_qty: confirmed_qty,
          delivered_qty: delivered_qty,
          stored_qty: stored_qty,
          product_id: product_id,
          action: 'PurchaseSummary'
        });
      });
      return tableData;
    },
    itemsFn() {
      this.CACHE.addCache(
        this.currentUser.uid,
        'PurchaseSummary',
        this.tableSubmit
      );
      this.loading = true;
      this.save_data = [];
      return apiBus.purchase
        .purchaseSummaryList(this.tableSubmit)
        .then(data => {
          // console.log(data);
          this.loading = false;
          this.tableData.items = this.searchTableData(data.data.data.list);
          this.tableData.totalItems = data.data.data.total;
          this.tableData.status = data.data.message;
          this.exportUrlFn(this.tableSubmit);
          return this.tableData.items;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
          this.tableData.totalItems = 0;
          return [];
        });
    },
    ifAllCheckbox() {
      var checkboxLength = [],
        ifCheckboxL = [];
      this.tableData.items.forEach(itme => {
        if (itme.select) {
          checkboxLength.push(itme.select);
        }
        if (itme.selectDisabled != 'disabled') {
          ifCheckboxL.push(itme.select);
        }
      });

      if (
        checkboxLength.length == ifCheckboxL.length &&
        ifCheckboxL.length > 0
      ) {
        this.tableData.fields[0].allSelect = true;
      } else {
        this.tableData.fields[0].allSelect = false;
      }
    },
    checkboxChange(select, index, itme) {
      var splice_index = this.save_data.indexOf(index);
      console.log(itme);
      // console.log(index);
      if (select) {
        this.save_data.push(index);
      } else {
        this.save_data.splice(splice_index, 1);
      }
      this.ifAllCheckbox();
    },
    checkboxAll(select) {
      if (select) {
        this.tableData.items.forEach(item => {
          item.select = true;
        });
      } else {
        this.tableData.items.forEach(item => {
          item.select = false;
        });
      }
    },
    add0(m) {
      return m < 10 ? '0' + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      return y + '-' + this.add0(m) + '-' + this.add0(d);
    },
    init() {
      if (this.CACHE.ifCache('PurchaseSummary')) {
        this.tableSubmit = this.CACHE.getCache(
          this.currentUser.uid,
          'PurchaseSummary'
        );
        Object.keys(this.searchConfiguration).forEach(key => {
          if (this.searchConfiguration[key].type == 'checked-all') {
            if (
              this.tableSubmit[key].length ==
              this.searchConfiguration[key].options.length
            ) {
              this.searchConfiguration[key].allSelected = true;
            } else {
              this.searchConfiguration[key].allSelected = false;
            }
          }
        });
        this.$refs.TAdvancedSearch.visible = this.CACHE.getVisible(
          'PurchaseSummary'
        );
      } else {
        this.tableSubmit = this.initialTableSubmit;
      }

      // apiBus.purchase
      //   .getSupplierList()
      //   .then(data => {
      //     console.log(data);
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
      apiBus.purchase
        .getSupplierList()
        .then(data => {
          this.options.supplier_list =
            data.data.data.supplier_list['Other Supplier'];
        })
        .catch(error => {
          console.log(error);
          this.options.supplier_list = [];
        });
    }
  },

  computed: {
    ...mapState({
      baseConfig: state => state.baseconfig.baseconfig
    }),
    ...mapGetters(['currentUser']) // 获取用户名
  },

  mounted() {
    this.init();
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId == 'advanced-search') {
        this.CACHE.addVisible('PurchaseSummary', isJustShown);
      }
    });
    if (this.options.sales_channel.length == 0) {
      if (Object.keys(this.baseConfig).length == 0) {
        apiBus.purchase
          .getBaseConfig()
          .then(data => {
            console.log('getBaseConfig数据缓存vuex');
            this.options.sales_channel = data.data.data.sales_channel;
            this.$store.dispatch(RENEW_BASE_CONFIG, data.data.data);
            // 给Order Type赋值
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        console.log('有数据');
        this.options.sales_channel = this.baseConfig.sales_channel;
      }
    }
  },
  watch: {
    'options.sales_channel'(o) {
      this.searchConfiguration.sales_channel.options = o;
    }
  }
};
</script>
<style scoped></style>
